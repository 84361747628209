import React, {useEffect, useRef} from 'react';
import {useControllerProps} from '../Widget/ControllerContext';
import {CustomField} from './CustomField/CustomField';
import {ShippingDetails} from './ShippingDetails/ShippingDetails';
import {DeliveryMethodFastFlow} from './DeliveryMethodFastFlow/DeliveryMethodFastFlow';
import {Text} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {classes} from './PlaceOrderFastFlow.st.css';
import {FastFlowAnalyticsEventParams} from '../../../domain/utils/analytics.utils';
import {Checkboxes} from '../Checkboxes/Checkboxes';
import {ErrorDialog} from '../ErrorDialog/ErrorDialog';
import {ViolationsAndPlaceOrderButton} from '../ViolationsAndPlaceOrderButton/ViolationsAndPlaceOrderButton';
import {ViolationsAndPlaceOrderButtonLocation} from '../../../types/app.types';
import {FastFlowForm} from './FastFlowForm/FastFlowForm';
import {BottomMobileSummary, BottomMobileSummaryDataHooks} from '../Summary/BottomMobileSummary/BottomMobileSummary';
import {classes as commonClasses} from '../common.st.css';

export enum PlaceOrderFastFlowDataHook {
  root = 'PlaceOrderFastFlow.root',
  title = 'PlaceOrderFastFlow.title',
  violations = 'PlaceOrderFastFlow.violations',
}

export const PlaceOrderFastFlow = () => {
  const {
    checkoutStore: {isMissingCustomField, placeOrderError, checkout},
    navigationStore: {trackEvent},
    checkboxesStore: {shouldShowCheckboxesSection},
    checkoutSettingsStore: {doesFastFlowFormHaveFieldsToDisplay},
    isOneColumnView,
  } = useControllerProps();
  const localeKeys = useLocaleKeys();
  const checkboxesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    trackEvent(...FastFlowAnalyticsEventParams);
  }, [trackEvent]);

  const shouldShowFastFlowForm = doesFastFlowFormHaveFieldsToDisplay;

  return (
    <div data-hook={PlaceOrderFastFlowDataHook.root}>
      <div className={commonClasses.oneColumnViewPadding}>
        <Text tagName={'h2'} className={classes.title} data-hook={PlaceOrderFastFlowDataHook.title}>
          {localeKeys.checkout.place_order_fast_flow.title()}
        </Text>
        <ShippingDetails />
        <DeliveryMethodFastFlow />
        <div
          className={
            isMissingCustomField || shouldShowFastFlowForm || shouldShowCheckboxesSection
              ? classes.section
              : classes.buttonOnlySection
          }>
          {!shouldShowFastFlowForm && isMissingCustomField && <CustomField />}
          {shouldShowFastFlowForm && <FastFlowForm extendedFields={checkout.extendedFields} />}
          <Checkboxes checkboxesRef={checkboxesRef} />
        </div>
      </div>

      {isOneColumnView ? (
        <BottomMobileSummary dataHook={BottomMobileSummaryDataHooks.placeOrderFastFlow} />
      ) : (
        <ViolationsAndPlaceOrderButton
          location={ViolationsAndPlaceOrderButtonLocation.fastFlow}
          checkboxesRef={checkboxesRef}
        />
      )}
      {(checkout.errors.noItemsError || placeOrderError) && <ErrorDialog />}
    </div>
  );
};
